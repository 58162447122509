import { ReactElement } from 'react';
import { useIsDraft } from '../ContextProviders/AppContext';
import { CategoryNavEdit } from './CategorySort/CategoryNavEdit';
import { CategoryNavPublish } from './CategoryNavPublish';
import { useAuth } from '../ContextProviders/Auth';
import { useCollection, useDocument } from '../../Hooks/Database';
import { CMSUser, WithID } from '../../Types';
import { StaffRoleTire } from '@eir/core';

export const CategoryNav = ({ id }: { id: string }): ReactElement => {
  const auth = useAuth();
  const { doc: user } = useDocument<CMSUser>(`cmsUser/${auth.user?.uid}`, {});
  const { docs: staffRoleTires } = useCollection<StaffRoleTire & WithID>('/roles', {});

  const loggedInUserRole = staffRoleTires.find((role) => role.fId === user.roleTire);
  const subprojectAdminCategories = loggedInUserRole?.categoryEditPermissions ?? [];
  const [isDraft] = useIsDraft();

  return isDraft && auth.isAdmin && !auth.isSubprojectAdmin ? (
    <CategoryNavEdit id={id} key={id} />
  ) : isDraft && auth.isAdmin && auth.isSubprojectAdmin && subprojectAdminCategories?.includes(id) ? (
    <CategoryNavEdit id={id} key={id} />
  ) : (
    <CategoryNavPublish id={id} key={id} />
  );
};
