import { ReactElement } from 'react';
import { useIsDraft } from '../ContextProviders/AppContext';
import { ArticleBodyEdit } from './ArticleBodyEdit';
import { ArticleBodyPublish } from './ArticleBodyPublish';
import './ArticleBody.scss';
import { FabButtonArticle } from '../FabButton/FabButton';
import { useAuth } from '../ContextProviders/Auth';
import { useCollection, useDocument } from '../../Hooks/Database';
import { CMSUser, WithID } from '../../Types';
import { StaffRoleTire } from '@eir/core';

interface ArticleBodyProps {
  id: string;
  categoryId: string;
}
export const ArticleBody = ({ id, categoryId }: ArticleBodyProps): ReactElement => {
  const [isDraft] = useIsDraft();
  const auth = useAuth();
  const { doc: user } = useDocument<CMSUser>(`cmsUser/${auth.user?.uid}`, {});
  const { docs: staffRoleTires } = useCollection<StaffRoleTire & WithID>('/roles', {});

  const loggedInUserRole = staffRoleTires.find((role) => role.fId === user.roleTire);
  const subprojectAdminCategories = loggedInUserRole?.categoryEditPermissions ?? [];

  return (
    <div style={{ display: 'flex' }}>
      <FabButtonArticle id={id} />
      {isDraft && auth.isAdmin && !auth.isSubprojectAdmin ? (
        <ArticleBodyEdit id={id} />
      ) : isDraft && auth.isAdmin && auth.isSubprojectAdmin && subprojectAdminCategories?.includes(categoryId) ? (
        <ArticleBodyEdit id={id} />
      ) : (
        <ArticleBodyPublish id={id} key={id} />
      )}
    </div>
  );
};
